import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch, usePost } from '../../../hooks';
import { useTheme } from '../../../context/ThemeContext';
import { useSnapshotSender } from '../../../hooks/useSnapshotSender';
import { RunnerFactory } from '../components/runners/RunnerFactory';
import StudentTasksView from './StudentTasksView';

const StudentTasksContainer = () => {
    const { topicId } = useParams();
    const { darkMode } = useTheme();
    const [theme, setTheme] = useState(() => darkMode ? 'monokai' : 'github');
    const [editorContent, setEditorContent] = useState('');
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [outputMode, setOutputMode] = useState('html');
    const [codeOutput, setCodeOutput] = useState('');
    const [runCounter, setRunCounter] = useState(0);
    const [showConsole, setShowConsole] = useState(false);
    const [consoleLogCount, setConsoleLogCount] = useState(0);
    const [consoleOutput, setConsoleOutput] = useState([]);
    const [showProgressModal, setShowProgressModal] = useState(false);

    const fetchCount = useRef(0);
    const isInitialMount = useRef(true);

    const { data: tasksList } = useFetch(
        'STUDENT_TOPIC_INSTANCE_TASKS',
        topicId ? [topicId] : null,
        !!topicId
    );

    const {
        data: taskDetails,
        loading: taskLoading,
        error: taskError,
        fetchData: refreshTaskDetails
    } = useFetch(
        selectedTaskId ? 'STUDENT_TASK_INSTANCE_DETAILS' : null,
        selectedTaskId ? [selectedTaskId] : undefined
    );

    const { postData } = usePost();

    useSnapshotSender(selectedTaskId, editorContent, false, taskDetails?.type, false);

    const handleConsoleMessage = useCallback((message) => {
        setConsoleOutput(prev => {
            const newOutput = [...prev, {
                level: message.level || 'log',
                content: message.content,
                isTable: message.isTable,
                tableData: message.tableData
            }];
            setConsoleLogCount(newOutput.length);
            return newOutput;
        });
    }, []);

    const clearConsole = useCallback(() => {
        setConsoleOutput([]);
        setConsoleLogCount(0);
    }, []);

    const handleResetCode = useCallback(async () => {
        if (!selectedTaskId) return;

        try {
            const response = await postData(
                'STUDENT_TASK_INSTANCE_ORIGINAL',
                null,
                [selectedTaskId]
            );

            if (response?.code !== undefined) {
                setEditorContent(response.code);
                setCodeOutput('');
                clearConsole();
            }
        } catch (error) {
            setCodeOutput('Błąd: Nie udało się zresetować kodu');
        }
    }, [selectedTaskId, postData, clearConsole]);

    const handleRunCode = useCallback(async () => {
        clearConsole();

        try {
            if (taskDetails?.language === 'javascript') {
                // Dla JavaScript zostawiamy obecną logikę
                setCodeOutput(editorContent);
                setOutputMode('html');
                setRunCounter(prev => prev + 1);
            } else if (taskDetails?.language === 'python') {
                const runner = RunnerFactory.createRunner('python', { darkMode });
                const result = await runner.run(editorContent, { language: 'python' });
                setCodeOutput(result.output);
                setOutputMode(result.mode || 'text');
                setRunCounter(prev => prev + 1);
            } else {
                // Dla pozostałych języków używamy endpointu
                const response = await postData('EXECUTE_CODE', {
                    code: editorContent,
                    language: taskDetails?.language
                });

                if (response.output) {
                    setCodeOutput(response.output);
                    setOutputMode('text');
                } else if (response.error) {
                    setCodeOutput(`Błąd: ${response.error}`);
                    setOutputMode('text');
                } else {
                    setCodeOutput('Brak wyniku lub wystąpił nieznany błąd.');
                    setOutputMode('text');
                }
                setRunCounter(prev => prev + 1);
            }
        } catch (err) {
            setCodeOutput(`Wystąpił błąd podczas wykonywania kodu: ${err.message}`);
            setOutputMode('text');
            setRunCounter(prev => prev + 1);
        }
    }, [clearConsole, taskDetails, editorContent, darkMode, postData]);

    const handleTaskSelect = useCallback((taskId) => {
        if (taskId === selectedTaskId) return;
        setSelectedTaskId(taskId);
        setCodeOutput('');
        clearConsole();
    }, [selectedTaskId, clearConsole]);

    const handleShowProgressModal = useCallback(() => {
        setShowProgressModal(true);
    }, []);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else if (topicId) {
            fetchCount.current += 1;
            setSelectedTaskId(null);
            setEditorContent('');
            setCodeOutput('');
            clearConsole();
        }
    }, [topicId, clearConsole]);

    useEffect(() => {
        if (selectedTaskId) {
            refreshTaskDetails();
        }
    }, [selectedTaskId, refreshTaskDetails]);

    useEffect(() => {
        if (taskDetails && 'code' in taskDetails) {
            setEditorContent(taskDetails.code || '');
        }
    }, [taskDetails]);

    useEffect(() => {
        setTheme(darkMode ? 'monokai' : 'github');
    }, [darkMode]);

    useEffect(() => {
    }, [tasksList]);

    return (
        <StudentTasksView
            tasksList={tasksList}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={handleTaskSelect}
            taskDetails={taskDetails}
            taskLoading={taskLoading}
            taskError={taskError}
            theme={theme}
            setTheme={setTheme}
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            outputMode={outputMode}
            setOutputMode={setOutputMode}
            codeOutput={codeOutput}
            onRunCode={handleRunCode}
            onResetCode={handleResetCode}
            runCounter={runCounter}
            showConsole={showConsole}
            setShowConsole={setShowConsole}
            consoleLogCount={consoleLogCount}
            consoleOutput={consoleOutput}
            onConsoleMessage={handleConsoleMessage}
            setConsoleLogCount={setConsoleLogCount}
            showProgressModal={showProgressModal}
            setShowProgressModal={setShowProgressModal}
            onShowProgressModal={handleShowProgressModal}
        />
    );
};

export default React.memo(StudentTasksContainer);
